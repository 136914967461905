.startOverButton {
  &__button {
    @mixin size 2.1875rem;
    padding: 0;
    margin-left: 0.625rem;

    &:hover svg {
      fill: var(--color_secondary);
    }

    &:hover path {
      fill: var(--color_live-tv-start-over-icon-arrow-hover);
    }

    @media (--mq-from-tablet) {
      @mixin size 2.375rem;
    }
  }

  &__icon {
    @mixin size 1.75rem;
    fill: var(--color_live-tv-start-over-icon);
    transition: fill 300ms ease;

    path {
      fill: var(--color_live-tv-start-over-icon-arrow);
    }
  }
}
