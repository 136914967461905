.headlineLiveTv {
  @media (--mq-from-tablet) {
    display: flex;
  }

  &__cardWrapper {
    @media (--mq-from-tablet) {
      flex: 1;
    }
  }

  &__headlineWrapper {
    @media (--mq-from-tablet) {
      display: flex;
      flex: 1;
      flex-direction: row;
      background: var(--color_grey-850);
      border: 0.0625rem solid var(--color_grey-350);
      border-left: 0;

      @mixin isDark {
        background: var(--od-color_darkmode-primary);
        border: 0.0625rem solid var(--color_darkmode-secondary);
      }
    }

    @media (--mq-from-desktop) {
      flex: 2;
    }

    @media (--mq-from-large-desktop) {
      flex: 3;
    }

    @media (--mq-from-wide) {
      flex: 4;
    }

    @mixin tvResize {
      flex: 4;
    }
  }

  &__headlineContainer {
    white-space: normal;

    @media (--mq-from-tablet) {
      margin: 1.25rem;
    }

    @media (--mq-from-laptop) {
      margin: 1.875rem;
    }
  }

  &__title {
    @media (--mq-from-tablet) {
      max-width: 20rem;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.125rem;
      color: var(--color_black);

      @mixin isDark {
        color: var(--color_darkmode-textcolor-primary);
      }
    }

    @media (--mq-from-laptop) {
      max-width: 26.75rem;
    }

    @media (--mq-from-desktop) {
      max-width: 45rem;
    }
  }

  &__subtitle {
    @media (--mq-from-tablet) {
      max-width: 20rem;
      margin: 0 0 0.9375rem;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-weight: 100;
      color: var(--color_black);

      @mixin isDark {
        color: var(--color_darkmode-textcolor-primary);
      }
    }

    @media (--mq-from-laptop) {
      max-width: 26.75rem;
    }

    @media (--mq-from-desktop) {
      max-width: 45rem;
    }
  }

  &__summary {
    @media (--mq-from-tablet) {
      display: -webkit-box;
      max-width: 20rem;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      font-size: 1rem;
      color: var(--color_primary-light);
      white-space: normal;
      -webkit-box-orient: vertical;
    }

    @media (--mq-from-laptop) {
      max-width: 26.75rem;
      -webkit-line-clamp: 4;
    }

    @media (--mq-from-desktop) {
      max-width: 45rem;
    }
  }
}
