:root {
  --contentRow-marginItem-horizontal-for-tv: 0.3125rem;
  --contentRow-marginItem-vertical-for-tv: 1.625rem;
  --contentRow-marginItem-for-tv: var(--contentRow-marginItem-horizontal-for-tv)
    var(--contentRow-marginItem-vertical-for-tv);
  --contentRow-marginItem-for-large-desktop: 0.3125rem;
  --contentRow-marginItem-for-tablet: 0.3125rem;
  --contentRow-marginItem-for-small: 0.1875rem;

  --contentRow-top10-rank-container-width-for-tv: 5.813rem;
  --contentRow-top10-rank-container-width-for-desktop: 3.75rem;
  --contentRow-top10-rank-container-width-for-tablet: 3.125rem;
  --contentRow-top10-rank-container-width-for-small: 2.5rem;

  --contentRow-top10-rank-number-width-for-tv: 9.27294rem;
  --contentRow-top10-rank-number-width-for-desktop: 6rem;
  --contentRow-top10-rank-number-width-for-tablet: 5.09881rem;
  --contentRow-top10-rank-number-width-for-small: 4rem;
}

.contentRowTemplateItem {
  vertical-align: top;

  @mixin responsiveGrid contentRow;
  @mixin responsiveGrid contentRowTv, true;

  @mixin paddingPages var(--contentRow-marginItem-for-large-desktop),
    var(--contentRow-marginItem-for-tablet),
    var(--contentRow-marginItem-for-small),
    var(--contentRow-marginItem-for-small);

  @mixin tvDevice {
    @mixin paddingPages var(--contentRow-marginItem-for-tv),
      var(--contentRow-marginItem-for-tv), var(--contentRow-marginItem-for-tv),
      var(--contentRow-marginItem-for-tv);
    /* Security margins for scaled element on focus to prevent cropping (eg: ButtonLogo) */
    margin-top: 1px;
    margin-bottom: 1px;

    /* TODO: replace this temporary recfix with #13013 */
    &--isBottomTitle {
      p {
        white-space: normal;
      }
    }
  }

  p {
    white-space: normal;
  }

  &--logoTyped {
    @mixin responsiveGrid contentRowLogoTyped;

    &--top10 {
      @mixin responsiveGrid contentRowLogoTyped, false,
        var(--contentRow-top10-rank-container-width-for-small);

      @media (--mq-from-tablet) {
        @mixin responsiveGrid contentRowLogoTyped, false,
          var(--contentRow-top10-rank-container-width-for-tablet);
      }

      @media (--mq-from-desktop) {
        @mixin responsiveGrid contentRowLogoTyped, false,
          var(--contentRow-top10-rank-container-width-for-desktop);
      }
    }
  }

  &--liveTv {
    p {
      white-space: nowrap;
    }
  }

  &--immersive {
    @mixin responsiveGrid contentRowImmersive;
  }

  &--carousel > :first-child {
    @mixin aspectRatioLegacy 16, 6, -1px;
  }

  &--detailV5 {
    @mixin responsiveGrid contentRowDetailV5;
  }

  &--creativeMedia {
    @mixin responsiveGrid creativeMedia;
  }

  &--creativeMediaInDetailV5 {
    @mixin responsiveGrid contentRowCreativeMediaInDetailV5;
  }

  &--top10 {
    @mixin responsiveGrid contentRow, false,
      var(--contentRow-top10-rank-container-width-for-small);

    @media (--mq-from-tablet) {
      @mixin responsiveGrid contentRow, false,
        var(--contentRow-top10-rank-container-width-for-tablet);
    }

    @media (--mq-from-desktop) {
      @mixin responsiveGrid contentRow, false,
        var(--contentRow-top10-rank-container-width-for-desktop);
    }

    @mixin responsiveGrid contentRowTv, true,
      var(--contentRow-top10-rank-container-width-for-tv);
  }

  &__itemLink {
    display: block;
    width: 100%;

    &--top10 {
      display: flex;
      flex-flow: row nowrap;

      &__media {
        width: 100%;
      }
    }
  }

  &__fullWidth {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
}

/* top 10 */
.rankItemStandard {
  --contentRow-top10-grandient: linear-gradient(
    to bottom right,
    rgba(33, 36, 41, 0) 39.27%,
    rgba(33, 36, 41, 0.44) 55.44%,
    #212429 85.02%
  );

  [data-theme~='NEW-TIM'] & {
    --contentRow-top10-gradient: linear-gradient(
      132.4deg,
      rgba(0, 50, 100, 0) 39.27%,
      rgba(0, 50, 100, 0.442708) 55.44%,
      var(--color_gold-dark-secondary) 85.02%
    );
  }

  position: relative;
  width: var(--contentRow-top10-rank-container-width-for-small);
  height: auto;
  font-family: var(--font_canal);
  font-size: 3.5rem;
  line-height: normal;
  letter-spacing: -0.45rem;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: var(--contentRow-top10-gradient);
  }

  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: var(--contentRow-top10-rank-number-width-for-small);
    height: 100%;
    transform: translate(
      calc(-0.49rem - var(--contentRow-marginItem-for-small))
    );

    @media (--mq-from-tablet) {
      width: var(--contentRow-top10-rank-number-width-for-tablet);
      transform: translate(
        calc(-0.51rem - var(--contentRow-marginItem-for-tablet))
      );
    }

    @media (--mq-from-desktop) {
      width: var(--contentRow-top10-rank-number-width-for-desktop);
      transform: translate(
        calc(-0.56rem - var(--contentRow-marginItem-for-large-desktop))
      );
    }

    @mixin tvDevice {
      width: var(--contentRow-top10-rank-number-width-for-tv);
      transform: translate(
        calc(-0.87rem - var(--contentRow-marginItem-horizontal-for-tv))
      );
    }
  }

  @media (--mq-from-tablet) {
    width: var(--contentRow-top10-rank-container-width-for-tablet);
    font-size: 4.0625rem;
  }

  @media (--mq-from-desktop) {
    width: var(--contentRow-top10-rank-container-width-for-desktop);
    font-size: 5rem;
  }

  @mixin tvDevice {
    width: var(--contentRow-top10-rank-container-width-for-tv);
    font-size: 7.625rem;
  }
}
